import useApi, {type ApiWrapper} from '~/service/api/_useApi';
import {Ref} from 'vue';
import type {Bank} from '~/models/bank';

export type BanksDictionary = {
  cash: Bank[]
  mortgage: Bank[]
  sme: Bank[]
}

export default function useDictionaryApi() {
  const {POST, FETCH,} = useApi();


  const getBanks = async(): ApiWrapper<Ref<BanksDictionary>> => {
    return FETCH<BanksDictionary, BanksDictionary>(
      `/banks/all/public`
    );
  };

  return {
    getBanks,
  };
}
